<template>
  <base-section id="theme-features">
  <span v-intersect ="handleIntersect"></span>
    <base-section-heading title="Who We Are">
      INCOGNIT CLOUD aims higher at ServiceNow solutions.
    </base-section-heading>
    <v-container>
      <v-sheet id="scroll-target" class="mx-auto" elevation="0" max-width="1200">
        <v-slide-group
          class="mx-auto"
          active-class="success"
          show-arrows
        >
          <v-slide-item
            v-for="(feature, i) in features"
            :key="i"
            v-slot="{ active }"
          >
            <base-avatar-card
              v-bind="feature"
              :color="active ? 'white' : '#97b9d9'"
              class="ma-4"
            >
              <v-row class="fill-height" align="center" justify="center">
                <v-scale-transition>
                  <v-icon
                    v-if="active"
                    color="#97b9d9"
                    size="48"
                    v-text="'mdi-close-circle-outline'"
                  ></v-icon>
                </v-scale-transition>
              </v-row>
            </base-avatar-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-container>
  </base-section>
</template>

<script>
import { EventBus } from '../../event-bus';
export default {
  name: "SectionThemeFeatures",

  data: () => ({
    features: [
      {
        title: "HIGH GROUND",
        icon: "mdi-bird",
        text: "We stand out from the crowd because we hire people for their superpower. People that make mistakes, learn, improve, share and have a word to say. Trust and transparency go a long way. We put them at the core of everything we do.",
      },
      {
        title: "HIGH STANDARD",
        text: "Our clients express praise for our commitment and expertise as ServiceNow developers. We are here to understand our clients, take care of their needs and facilitate solutions. Sounds simple, but the way we do it is a remarkable business empowering asset.",
        icon: "mdi-rocket-launch",
      },
      {
        title: "HIGH IMPACT",
        icon: "mdi-nuke",
        text: "No hidden secrets to Incognit Cloud’s success formula. A win-win mentality is what leads our team to the impact we proudly make every day.",
      },
    ],
  }),
  methods: {
      handleIntersect(entries, observer) {
			if(entries[0].isIntersecting) {
        EventBus.$emit('changeTab', "#theme-features" );
			}
		}
    }
};
</script>
